import { TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from 'src/utils/axios.defaults'
import { v4 } from 'uuid'

export class AnalyticApi {
  static _session = v4()
  static readonly _prefix = '/analytics'

  static resetSession() {
    this._session = v4()
  }

  static setSession(session: string) {
    this._session = session
  }

  static create({ data, ...payload }: {
    screen: string
    event: string
    data?: Record<string, unknown>
  }): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${this._prefix}`, {
      ...payload,
      data: data || {},
      session: this._session
    })
  }
}
