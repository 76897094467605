import { AxiosError } from 'axios'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AnalyticApi, AuthApi } from 'src/api'
import { EMessage } from 'src/enums'
import { useAppDispatch, useAppSelector, useQueryParams, useUnsubscribeEffect } from 'src/hooks'
import { EAuthView } from 'src/interfaces'
import { RegisterEmail } from 'src/partials'
import { SnackbarService } from 'src/services'
import { setLayoutLoading } from 'src/store/actions'
import { getIsAuthenticated } from 'src/store/selectors'
import { AUTH_GET_PROFILE } from 'src/store/types'
import { decodeJWTPayload, getApiErrorMessage } from 'src/utils'
import { SetName, SetPassword, VerifyEmail } from './components'
import { Success } from './components/success'

type TSignUpPayload = Parameters<typeof AuthApi.signUpProfile>[0]

export const SignUp: FC = () => {
  const { token, emailSent } = useQueryParams()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const [emailCampaignHashId, setEmailCampaignHashId] = useState<string | undefined>('')

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
  }, [history, isAuthenticated])

  const [view, setView] = useState<EAuthView>(EAuthView.EMAIL_REGISTER)
  const [formValues, setFormValues] = useState<TSignUpPayload>({
    password: '',
    interested: '',
    subscribeMarketingEmails: true,
    name: ''
  })

  const handleChangeFormData = useCallback((name: string, value: TSignUpPayload[keyof TSignUpPayload]): void => {
    setFormValues(
      (prev) => ({
        ...prev,
        [name]: value
      })
    )
  }, [])

  const handleSubmit = useCallback(async (information: Partial<TSignUpPayload>) => {
    try {
      const submitData: TSignUpPayload = { ...formValues, ...information }

      if (emailCampaignHashId) {
        submitData.campaignHashId = emailCampaignHashId
      }

      await AuthApi.signUpProfile(submitData, { token: token as string })
      const { fromGuestView } = decodeJWTPayload(token as string)

      history.push({
        pathname: history.location.pathname,
        search: ''
      })

      dispatch({ type: AUTH_GET_PROFILE, payload: { afterSignUp: true, fromGuestView } })
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    }
  }, [dispatch, emailCampaignHashId, formValues, history, token])

  useUnsubscribeEffect((unsubscribe$) => {
    if (token) {
      dispatch(setLayoutLoading(true))
      from(AuthApi.signUpVerify({ token: token as string }))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error: AxiosError) => {
            const message = (error as AxiosError).response?.status === 406
              ? 'Sign out first then open the link again'
              : getApiErrorMessage(error)
            SnackbarService.push({
              severity: EMessage.ERROR,
              content: message
            })
            return EMPTY
          }),
          finalize(() => dispatch(setLayoutLoading(true)))
        )
        .subscribe(({ data }) => {
          const { sessionId } = decodeJWTPayload(token as string)

          if (sessionId) {
            AnalyticApi.setSession(sessionId)
          }

          setEmailCampaignHashId(data.campaignHashId)
          setView(EAuthView.NAME)
        })
    }
  }, [token])

  const renderView = useMemo(() => {
    const passingProps = {
      onSubmit: handleSubmit,
      handleChangeFormData,
      setView,
      signUpInfo: formValues
    }

    if (emailSent) {
      return <VerifyEmail/>
    }

    switch (view) {
      case EAuthView.EMAIL_REGISTER:
        return <RegisterEmail onChangeView={() => setView(EAuthView.EMAIL_VERIFIED)}/>

      case EAuthView.EMAIL_VERIFIED:
        return <VerifyEmail/>

        // case EAuthView.EMAIL_VERIFIED_SUCCESS:
        //   return <VerifyEmailSuccess onChangeView={() => setView(EAuthView.PASSWORD)}/>

      case EAuthView.NAME:
        return <SetName onChangeView={() => setView(EAuthView.PASSWORD)} {...passingProps}/>

      case EAuthView.PASSWORD:
        return <SetPassword {...passingProps}/>

      case EAuthView.SUCCESS:
        return <Success/>

      default:
        return <SetPassword {...passingProps}/>
    }
  }, [handleSubmit, handleChangeFormData, formValues, emailSent, view])

  return (
    <>
      {/* {[EAuthView.PASSWORD, EAuthView.EMAIL_VERIFIED_SUCCESS].includes(view) && renderProgress} */}
      {renderView}
    </>
    // <NotAllowAuthenticated>
    //   {[EAuthView.PASSWORD, EAuthView.EMAIL_VERIFIED_SUCCESS].includes(view) && renderProgress}
    //   {renderView}
    // </NotAllowAuthenticated>
  )
}
