import { AxiosError } from 'axios'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import {
  EMPTY,
  catchError,
  combineLatest,
  from,
  map,
  of,
  switchMap,
  take,
  takeUntil
} from 'rxjs'
import { JobPostedSuccess } from 'src/components/job-posted-success'
import { EMessage } from 'src/enums'
import {
  useAppDispatch,
  useAppSelector,
  useBehaviorMapper,
  useQueryParams,
  useSubscribeToGoBack,
  useUnsubscribe,
  useUnsubscribeEffect,
  useValidation
} from 'src/hooks'
import { ERoutes, generate } from 'src/router'
import {
  CampaignMutationService,
  CampaignService,
  NavigationService,
  OverlayService,
  ProfileService,
  SnackbarService
} from 'src/services'
import { CreateJobTourService } from 'src/services/tour/create-job-tour.service'
import { PopupTourService } from 'src/services/tour/popup.service'
import { EUploadStatus } from 'src/services/upload.service'
import {
  setLayoutLoading,
  setLayoutPageComponent,
  setLayoutPageTitle,
  setLayoutShouldShowLoading
} from 'src/store/actions'
import { getProfile } from 'src/store/selectors'
import { AUTH_GET_PROFILE } from 'src/store/types'
import { useUploadStatus } from '../../hooks/useUploadStatus'
import { getCampaignShareLink } from '../../utils'
import { ActionButtons } from './components/action-buttons'
import { CampaignDetail } from './components/detail'
import { CampaignInformation } from './components/information'
import Style from './style.module.scss'
import { createCampaignSchema } from './validation'

const PAGE_TITLE = 'My Jobs/Create Job'

export const NewCampaign: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const dispatch = useAppDispatch()
  const router = useHistory()
  const data = useBehaviorMapper(CampaignMutationService.data$)
  const profile = useAppSelector(getProfile)
  const { draftId } = useQueryParams()
  const tourEnabled = useBehaviorMapper(CreateJobTourService.enableTour$)
  const currentTourStep = useBehaviorMapper(CreateJobTourService.currentStep$)
  const uploadStatus = useUploadStatus()

  useUnsubscribeEffect(
    (unsubscribe$) => {
      if (draftId) {
        CampaignService.fetchCampaignDraft(+draftId, unsubscribe$)
      }
    },
    [draftId]
  )

  useEffect(() => {
    dispatch(setLayoutPageTitle(PAGE_TITLE))
    dispatch(setLayoutPageComponent(null))
    dispatch(setLayoutShouldShowLoading(true))

    NavigationService.setCreditPoint(false)

    return () => {
      NavigationService.setCreditPoint(true)
    }
  }, [dispatch])

  useSubscribeToGoBack({
    showArrow: true,
    title: PAGE_TITLE,
    titleActions: [
      {
        title: 'My Jobs',
        handler: () => router.push(generate(ERoutes.CAMPAIGN_MY_JOBS))
      },
      { title: 'Create Job' || '', handler: () => null }
    ]
  })

  useEffect(() => {
    combineLatest([
      CampaignMutationService.loading$,
      CampaignService.loading$,
      PopupTourService.loadingFinish$
    ])
      .pipe(
        takeUntil(unsubscribe$),
        map(
          ([campaignMutationLoading, campaignLoading, loadingFinishTour]) =>
            campaignMutationLoading || campaignLoading || loadingFinishTour
        )
      )
      .subscribe((val) => dispatch(setLayoutLoading(val)))
  }, [dispatch, unsubscribe$])

  useEffect(() => {
    if (
      !data.companyName &&
      data.companyWebsiteUrls?.at(0) === '' &&
      !data.logoUrl &&
      !CampaignMutationService.draftId
    ) {
      const toPrefillData: Record<string, unknown> = { _ignoreDraft: true }
      if (profile.companyLogo) {
        toPrefillData.logoUrl = profile.companyLogo
      }

      if (profile.companyName) {
        toPrefillData.companyName = profile.companyName
      }

      if (profile.companyUrls) {
        toPrefillData.companyWebsiteUrls = profile.companyUrls
      }

      CampaignMutationService.patchData(toPrefillData)
    }
  }, [profile, data.companyName, data.companyWebsiteUrls, data.logoUrl])

  const postJobEnabled = useMemo(
    () =>
      (tourEnabled
        ? CreateJobTourService.isLastStep(currentTourStep)
        : CampaignService.isDataValid(data)) &&
      (uploadStatus === EUploadStatus.COMPLETED ||
        uploadStatus === EUploadStatus.NOT_STARTED),
    [currentTourStep, data, tourEnabled, uploadStatus]
  )

  const [startValidating, setStartValidating] = useState(false)
  const { errors, validate } = useValidation({
    data,
    schema: createCampaignSchema
  })
  const [scrollingError, setScrollingError] = useState('')
  const [scrollingFirstError, setScrollingFirstError] = useState(false)
  const [firstScrolling, setFirstScrolling] = useState(false)

  const manualErrors = useMemo(
    () => ({
      briefDescription: startValidating && (
        data.briefDescription?.replace(/<(.|\n)*?>/g, '').trim().length === 0 &&
        !data.briefDescription.includes('<img') &&
        !data.jdFile &&
        !data.jdFileUrl),
      uploadVideo: startValidating && (!data.uploadVideoUrl && !data.uploadVideoFile)
    }),
    [data.briefDescription, data.jdFile, data.jdFileUrl, data.uploadVideoFile, data.uploadVideoUrl, startValidating]
  )

  useEffect(() => {}, [errors, manualErrors])

  const handleScrollToFirstError = useCallback((name: string) => {
    document.getElementById(name)?.scrollIntoView({
      behavior: 'smooth'
    })

    setScrollingError(`${name}Input`)

    setTimeout(() => {
      document.getElementById(`${name}Input`)?.focus()
    }, 500)

    setScrollingFirstError(false)
  }, [])

  useEffect(() => {
    if (scrollingFirstError) {
      if (firstScrolling) {
        setFirstScrolling(true)
        return
      }

      if (manualErrors.uploadVideo) {
        handleScrollToFirstError('uploadVideo')
        return
      }

      if (errors.hasError('jobTitle')) {
        handleScrollToFirstError('jobTitle')
        return
      }

      if (errors.hasError('salaryValue')) {
        handleScrollToFirstError('salaryValue')
        return
      }

      if (errors.hasError('location')) {
        handleScrollToFirstError('location')
        return
      }

      if (manualErrors.briefDescription) {
        handleScrollToFirstError('briefDescription')
        return
      }

      if (errors.hasError('companyName')) {
        handleScrollToFirstError('companyName')
        return
      }

      if (errors.hasError('companyWebsiteUrls[0]')) {
        handleScrollToFirstError('companyWebsiteUrls')
      }
    }
  }, [scrollingFirstError, startValidating, errors, manualErrors.uploadVideo, manualErrors.briefDescription, handleScrollToFirstError, firstScrolling, validate])

  const handleSubmitData = useCallback(async () => {
    await validate()
    setStartValidating(true)
    setScrollingFirstError(true)

    if (!postJobEnabled) {
      return
    }

    const slug = data.slug
    const tourEnabling = CreateJobTourService.enableTour$.getValue()

    from(CampaignMutationService.create())
      .pipe(
        take(1),
        switchMap(() => {
          if (tourEnabling) {
            return PopupTourService.completeMission('create-job')
          }

          return of(null)
        }),
        catchError((error: AxiosError) => {
          console.log('error', error)
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: 'Unexpected error, please try again'
          })
          return EMPTY
        })
      )
      .subscribe(() => {
        const hasVVC = !!ProfileService.settingProfile$.getValue().video
        dispatch({ type: AUTH_GET_PROFILE })
        router.push(generate(ERoutes.CAMPAIGN_MY_JOBS))

        const onFirstTimeUploadedPFV = () => {
          if (!hasVVC) {
            NavigationService.setFirstUploadedPfv(true)
          }
        }

        if (!tourEnabling) {
          OverlayService.setOverlay({
            content: <JobPostedSuccess url={getCampaignShareLink(slug)}/>,
            open: true,
            onClose: onFirstTimeUploadedPFV
          })
        } else {
          onFirstTimeUploadedPFV()
        }
      })
  }, [data.slug, dispatch, postJobEnabled, router, validate])

  return (
    <div className="relative">
      <div className={Style.container}>
        <div className={Style.content}>
          <div className={Style.content_wrapper}>
            <CampaignDetail
              errors={errors}
              otherErrors={startValidating ? manualErrors : {}}
              focusedErr={scrollingError}
            />

            <CampaignInformation errors={errors}/>
          </div>
        </div>
      </div>
      <div className={Style.content_action}>
        <ActionButtons onSubmit={handleSubmitData}/>
      </div>
    </div>
  )
}
