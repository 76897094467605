import { cloneDeep, debounce, isEqual } from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { CampaignApi, UploadApi } from 'src/api'
import { CAMPAIGN_SALARY_VALUE_RANGE_OPTIONS } from 'src/constants'
import { ELocationType } from 'src/enums'
import { EFileUploadKind, ESalaryRange, ESalaryRate, ICampaignDetail, ICampaignDraftModel, ICampaignModel, IFileUpload } from 'src/interfaces'
import { LockMonitor } from 'src/utils/lock-monitor.utils'
import { v4 as uuidv4 } from 'uuid'
import { PopupTourService } from '../tour/popup.service'

const defaultData: ICampaignDetail = {
  slug: '',
  jobTitle: '',
  companyWebsiteUrls: [''],
  salaryRange: ESalaryRange.CUSTOM,
  salaryValue: CAMPAIGN_SALARY_VALUE_RANGE_OPTIONS[0].value,
  salaryRate: ESalaryRate.PER_HOUR,
  location: '',
  allowRemote: false,
  questionIds: [],
  requireZipCode: false,
  companyName: '',
  locationType: ELocationType.ON_SITE,
  briefDescription: '',
  requiredResume: false,
  requireLinkedInUrl: false,
  shareOnMarket: true
}

interface IVideoUploadingTrackingId {
  /**
   * this field is used for upload percentage tracking
   */
  uploadVideoRefId?: string
}

export class CampaignMutationService {
  static lock = new LockMonitor()

  private static _isDirty = false
  public static get isDirty() {
    return this._isDirty
  }

  private static _isPristine = true
  public static get isPristine() {
    return this._isPristine
  }

  static async uploadFiles(payload: IFileUpload, seed: Record<string, unknown> = {}, options?: { id: string }) {
    if (payload.jdFile) {
      seed.jdFileUrl = await UploadApi.upload({
        kind: EFileUploadKind.JD_CAMPAIGN_FILE,
        file: payload.jdFile,
        filename: payload.jdFile.name
      })
    } else {
      seed.jdFileUrl = undefined
    }

    if (payload.logoFile) {
      seed.logoUrl = await UploadApi.upload({
        kind: EFileUploadKind.COMPANY_CAMPAIGN_PHOTO,
        file: payload.logoFile
      })
    } else {
      seed.logoUrl = undefined
    }

    if (payload.uploadVideoFile) {
      seed.uploadVideoKey = await UploadApi.upload({
        kind: payload.uploadVideoFile.type.startsWith('audio')
          ? EFileUploadKind.COMPANY_CAMPAIGN_AUDIO
          : EFileUploadKind.COMPANY_CAMPAIGN_VIDEO,
        file: payload.uploadVideoFile
      }, options)
    } else {
      seed.uploadVideoKey = undefined
    }

    const videoBg = payload.uploadAudioBackground
    if (videoBg?.file) {
      const file = videoBg.file
      seed.uploadAudioBgKey = await UploadApi.upload({
        kind: EFileUploadKind.COMPANY_CAMPAIGN_AUDIO_BG,
        file
      }, options)
    } else if (videoBg?.key) {
      seed.uploadAudioBgKey = videoBg.key
    } else {
      seed.uploadAudioBgKey = undefined
    }

    return seed as unknown as { id?: ICampaignModel['id'] } & Parameters<typeof CampaignApi.create>[0]
  }

  public static readonly loading$ = new BehaviorSubject<boolean>(false)

  public static readonly data$ = new BehaviorSubject<ICampaignDetail & IVideoUploadingTrackingId>(cloneDeep(defaultData))

  private static _draftId = 0

  public static get draftId() {
    return this._draftId
  }

  public static get data() {
    return this.data$.getValue()
  }

  public static setData(data: ICampaignDetail) {
    this._draftId = 0
    this._isDirty = false
    this._isPristine = true
    if (!data.salaryRate) {
      data.salaryRate = ESalaryRate.PER_HOUR
    }

    return this.data$.next(data)
  }

  public static setDraftData(data: ICampaignDraftModel) {
    const currentData = { ...this.data }
    if (!data.salaryRate) {
      data.salaryRate = ESalaryRate.PER_HOUR
    }
    const copyData: Record<string, unknown> = {}

    Object.entries(data).forEach(([key, value]) => {
      if (value !== null) {
        copyData[key] = value
      }
    })

    copyData.slug = copyData?.slug || uuidv4()

    const newData = {
      ...currentData,
      ...copyData
    }

    this._draftId = data.id || 0
    return this.data$.next(newData)
  }

  public static reset() {
    this.lock?.destroy()
    this.lock = new LockMonitor()

    this.data$.next(cloneDeep(defaultData))

    this._draftId = 0
    this._isDirty = false
    this._isPristine = true
  }

  public static uploadFilesAndSaveDraft = async (dataToCreate: IFileUpload, options?: { id: string }) => {
    const release = await this.lock.acquire()
    try {
      const currentData = this.data

      const fileUrls = await this.uploadFiles(dataToCreate, {
        id: currentData.id,
        draftId: this._draftId
      }, options)

      if (fileUrls.draftId) {
        delete fileUrls.id
      }

      const { data } = await CampaignApi.saveDraft({
        ...fileUrls,
        thumbnailOffset: dataToCreate.thumbnailOffset
      })

      if (data.id) {
        this._draftId = data.id
      }

      this._isPristine = false
      this._isDirty = false

      return fileUrls
    } finally {
      release()
    }
  }

  private static debounceSaveDraft = debounce(async (campaign: ICampaignDraftModel & IFileUpload) => {
    const release = await this.lock.acquire()

    try {
      const campaignDraft = { ...campaign }

      if (
        campaignDraft.uploadVideoFile ||
        campaignDraft.uploadAudioBackground ||
        campaignDraft.jdFile ||
        campaignDraft.logoFile
      ) {
        delete campaignDraft.uploadVideoFile
        delete campaignDraft.jdFile
        delete campaignDraft.logoFile
        delete campaignDraft.uploadAudioBackground
      }

      if (Object.keys(campaignDraft).length === 0) {
        return
      }

      if (this._draftId) {
        campaignDraft.draftId = this._draftId
        delete campaignDraft.id
      }

      const { data } = await CampaignApi.saveDraft(campaignDraft)
      this._isDirty = false

      // new campaign draft just created, we will use this id for next campaign save draft
      if (data.id) {
        this._draftId = data.id
      }
    } finally {
      release()
    }
  }, 500)

  public static patchData(params: Partial<ICampaignDetail & IVideoUploadingTrackingId> & { _ignoreDraft?: boolean }) {
    const { _ignoreDraft, ...data } = params
    const copyData = { ...this.data }
    copyData.slug = copyData?.slug || uuidv4()

    const newData = {
      ...(copyData || {}),
      ...data
    }

    if (!(Object.keys(data).length === 1 && data.companyWebsiteUrls && isEqual(data.companyWebsiteUrls, copyData.companyWebsiteUrls)) && !_ignoreDraft) {
      this._isDirty = true
      this._isPristine = false
      this.debounceSaveDraft(newData)
    }

    this.data$.next(newData)
  }

  public static async create() {
    try {
      this.loading$.next(true)

      await CampaignApi.create({
        ...this.data,
        questionIds: [1],
        draftId: this._draftId
      })

      this.reset()
    } catch (error) {
      console.log('error', error)
      throw error
    } finally {
      this.loading$.next(false)
    }
  }

  public static async archive(id: number) {
    try {
      this.loading$.next(true)
      await CampaignApi.archive(id)
      this.reset()
      PopupTourService.fetchMissionIfShowing()
    } catch (error) {
      console.log('error', error)
      throw error
    } finally {
      this.loading$.next(false)
    }
  }
}
