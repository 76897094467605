import { Typography } from '@mui/material'
import { ChangeEventHandler, Dispatch, FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { AuthApi } from 'src/api'
import { Button, Input, useAnalytic } from 'src/components'
import { MISMATCH_PASSWORD } from 'src/constants'
import { ETrackingEvent } from 'src/enums'
import { useOldValidation } from 'src/hooks'
import { IconLock } from 'src/icons'
import { Logo } from 'src/images'
import { EAuthView } from 'src/interfaces'
import { registerPasswordSchema } from 'src/validation'
import { Header } from '../../../header'
import Style from './style.module.scss'

interface ISetAccountInformation {
  company: string
  companyUrl: string
  password: string
  confirmPassword: string
}

type TSignUpPayload = Parameters<typeof AuthApi.signUpProfile>[0]

interface IProps {
  handleChangeFormData: (name: string, value: TSignUpPayload[keyof TSignUpPayload]) => void
  setView: Dispatch<EAuthView>
  signUpInfo: TSignUpPayload
  onSubmit: (data: Omit<ISetAccountInformation, 'confirmPassword'>) => Promise<void>
}

export const SetPassword: FC<IProps> = ({ handleChangeFormData, onSubmit }) => {
  const [formValues, setFormValues] = useState<ISetAccountInformation>({ company: '', companyUrl: '', password: '', confirmPassword: '' })
  const { errors, validateAt } = useOldValidation<ISetAccountInformation>()
  const { eventHandler } = useAnalytic('signup_password')
  const [loading, setLoading] = useState(false)

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    e.persist()
    const { name, value } = e.target
    validateAt({
      schema: registerPasswordSchema,
      path: name as keyof ISetAccountInformation,
      data: { ...formValues, [name]: value }
    })
    setFormValues(
      (prev) => ({
        ...prev,
        [name]: value
      })
    )
  }, [validateAt, formValues])

  const onNext = (e: FormEvent<HTMLFormElement>) => {
    setLoading(true)
    e.preventDefault()
    handleChangeFormData('company', formValues.company)
    handleChangeFormData('companyUrl', formValues.companyUrl)
    handleChangeFormData('password', formValues.password)
    onSubmit({
      company: formValues.company,
      companyUrl: formValues.companyUrl,
      password: formValues.password
    })
      .catch(() => setLoading(false))
  }

  useEffect(() => {
    if (errors.confirmPassword === MISMATCH_PASSWORD) {
      eventHandler(ETrackingEvent.ERROR_PSWD_MISMATCH)()
    }
  }, [errors.confirmPassword, eventHandler])

  const isInValid = useMemo(() =>
    Object.keys(errors)?.length <= 0 ||
      Object.values(errors).some((err) => err) ||
      Object.values(formValues).some((value) => !value)
  , [errors, formValues])

  return (
    <>
      <div className={Style.Header}>
        <Header/>
      </div>
      <div className={Style.Container}>

        <div className={Style.RightSide}>
          <div className={Style.STSetPasswordWrapper}>
            <form className={Style.STForm} onSubmit={onNext}>
              <div className={Style.Logo}>
                <img src={Logo} alt="logo"/>
              </div>
              <div className={Style.LoginHeader}>
                <Typography className={Style.Title}>Enter your Account Information</Typography>
              </div>
              <div className={Style.FormInput}>
                <Input
                  label="Company"
                  id="company"
                  name="company"
                  value={formValues.company || ''}
                  onChange={handleChange}
                  onClick={eventHandler(ETrackingEvent.INPUT_PSWD)}
                  error={errors.company}
                  required
                  mb={0}
                  labelClassName={Style.Label}
                  startIconTabIndex={-1}
                />
                <Input
                  label="Company URL"
                  id="companyUrl"
                  name="companyUrl"
                  value={formValues.companyUrl || ''}
                  onChange={handleChange}
                  onClick={eventHandler(ETrackingEvent.INPUT_PSWD_CONFIRM)}
                  error={errors.companyUrl}
                  required
                  mb={0}
                  labelClassName={Style.Label}
                  startIconTabIndex={-1}
                />
                <Input
                  label="Password"
                  id="password"
                  type="password"
                  name="password"
                  value={formValues.password || ''}
                  onChange={handleChange}
                  onClick={eventHandler(ETrackingEvent.INPUT_PSWD)}
                  error={errors.password}
                  required
                  mb={0}
                  labelClassName={Style.Label}
                  startIcon={<IconLock/>}
                  startIconTabIndex={-1}
                />
                <Input
                  label="Confirm your password"
                  id="confirmPassword"
                  type="password"
                  name="confirmPassword"
                  value={formValues.confirmPassword || ''}
                  onChange={handleChange}
                  onClick={eventHandler(ETrackingEvent.INPUT_PSWD_CONFIRM)}
                  error={errors.confirmPassword}
                  required
                  mb={0}
                  labelClassName={Style.Label}
                  startIcon={<IconLock/>}
                  startIconTabIndex={-1}
                />

                <Button
                  order="black"
                  kmSize="medium"
                  className="round-16"
                  disabled={isInValid || loading}
                  onClick={eventHandler(ETrackingEvent.BTN_PSWD_NEXT)}
                  type="submit"
                >
                  create account
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
