import { Avatar, Box, Typography, useTheme } from '@mui/material'
import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { ProfileApi, UploadApi } from 'src/api'
import { Button, Input, useAnalytic } from 'src/components'
import { EMessage, ETrackingEvent } from 'src/enums'
import {
  useAppDispatch,
  useAppSelector,
  useIfMobileScreen,
  useOldValidation,
  useUnsavedChanges
} from 'src/hooks'
import { DefaultCompany } from 'src/images'
import { EFileUploadKind } from 'src/interfaces'
import { SnackbarService } from 'src/services'
import { setLayoutLoading } from 'src/store/actions'
import { getCredentials } from 'src/store/selectors'
import { AUTH_GET_PROFILE } from 'src/store/types'
import { getApiErrorMessage } from 'src/utils'
import { editInformationSchema } from 'src/validation'
import { STFloatAction } from '../security/styled'
import { Setting } from '../setting'
import Style from '../setting/style.module.scss'
import { SettingTabService } from '../setting/tab.service'

interface IInformation {
  avatar?: {
    src?: string
    file?: File | null
  }
  firstName?: string
  lastName?: string
  username?: string
  email?: string
}

export const Information: FC = () => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const isMobile = useIfMobileScreen()
  const profile = useAppSelector(getCredentials)
  const { errors, setErrors, validateAt, validate } = useOldValidation<IInformation>()
  const [formData, setFormData] = useState<IInformation>({})
  const { eventHandler } = useAnalytic('settings_info')
  const [, setIsAvatarError] = useState(false)

  const isChanged = useMemo(
    () =>
      formData.avatar?.file ||
      useUnsavedChanges.isChange(formData.firstName, profile.firstName) ||
      useUnsavedChanges.isChange(formData.lastName, profile.lastName) ||
      useUnsavedChanges.isChange(formData.username, profile.username) ||
      useUnsavedChanges.isChange(formData.email, profile.email)
        ? true
        : null,
    [formData, profile]
  )

  const resetFormData = useCallback(async () => {
    setFormData({
      avatar: {
        src: profile.pfp?.url
      },
      firstName: profile.firstName,
      lastName: profile.lastName,
      username: profile.username,
      email: profile.email
    })
  }, [profile.firstName, profile.lastName, profile.username, profile.email, profile.pfp])

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    e.persist()
    const { name, value } = e.target
    validateAt({
      schema: editInformationSchema,
      path: name,
      data: { [name]: value }
    })
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleAvatar: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files?.[0]
    if (!file) return
    const ext = file.name.split('.').pop()
    if (!['jpg', 'jpeg', 'png'].includes(ext?.toLowerCase() || '')) {
      setIsAvatarError(true)
      return
    }

    // const src = (await readFilePromise(file)) as string
    const src = URL.createObjectURL(file)
    setFormData((prev) => ({
      ...prev,
      avatar: { src, file }
    }))
    setIsAvatarError(false)
  }

  const save = useCallback(async (payload = formData) => {
    try {
      const isValid = await validate({
        schema: editInformationSchema,
        data: payload
      })

      if (!isValid || !payload) {
        return
      }

      dispatch(setLayoutLoading(true))

      if (payload.avatar?.file) {
        const avatarFile = payload.avatar.file

        const key = await UploadApi.upload({
          kind: EFileUploadKind.PFP,
          file: avatarFile
        })

        await ProfileApi.uploadAvatar({ key })
      }

      await ProfileApi.updateBasic({
        firstName: payload.firstName,
        lastName: payload.lastName
      })

      dispatch({ type: AUTH_GET_PROFILE })

      SnackbarService.push({
        severity: EMessage.SUCCESS,
        content: 'Cool, Info Updated'
      })
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally {
      dispatch(setLayoutLoading(false))
    }
  }, [dispatch, formData, validate])

  useEffect(() => {
    resetFormData()
  }, [profile, resetFormData])

  const refFormData = useRef<typeof formData>()
  useEffect(() => {
    refFormData.current = isChanged ? formData : undefined
  }, [isChanged, formData])

  useUnsavedChanges({ isChanged, onSave: save, onClose: () => { SettingTabService.setTab(1) } })

  return (
    <Setting>
      <div className={Style.STFormContainer}>
        <div className={Style.STFormContentWrap}>
          <Typography variant="h5">Basic Information</Typography>

          <Box display="flex" mt={4} gap={2} alignItems="center" mb={4}>
            <Avatar
              src={formData.avatar?.src || DefaultCompany}
              alt="avatar"
              sx={{ width: 120, height: 120 }}
            />

            <div className={Style.uploadContainer}>
              <Button
                order="secondary"
                field="input"
                size="small"
                sx={{ borderRadius: 3, height: 'auto', paddingY: '8px' }}
                accept="image/jpeg,image/png"
                handleChange={handleAvatar}
                event={eventHandler(ETrackingEvent.BTN_PROFILE_PIC_CHANGE)}
              >
                Upload
              </Button>
              <div className={Style.uploadNote}>PNG, JPG (max 800x800px)</div>
            </div>
          </Box>

          <Box display="flex" gap={2}>
            <Input
              label="First Name"
              id="firstName"
              name="firstName"
              placeholder="First name"
              value={formData.firstName || ''}
              onChange={handleChange}
              onClick={eventHandler(ETrackingEvent.INPUT_FIRST_NAME)}
              autoComplete="off"
              error={errors.firstName}
              required
              labelClassName={Style.customLabel}
            />
            <Input
              label="Last Name"
              id="lastName"
              name="lastName"
              placeholder="Last name"
              value={formData.lastName || ''}
              onChange={handleChange}
              onClick={eventHandler(ETrackingEvent.INPUT_LAST_NAME)}
              autoComplete="off"
              error={errors.lastName}
              required
              labelClassName={Style.customLabel}
            />
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              variant="meta-bold"
              color={theme.colors['--color-neutral-theme-500']}
            >
              Work Email
            </Typography>
            <Typography variant="body1-regular">{formData.email}</Typography>
          </Box>
        </div>
      </div>

      {isChanged && (
        <STFloatAction isMobile={isMobile}>
          <Button
            order="secondary"
            {...(isMobile ? { sx: { flex: 1 } } : { width: 177 })}
            onClick={eventHandler(ETrackingEvent.BTN_CANCEL, () => {
              setErrors({})
              resetFormData()
            })}
          >
            Cancel
          </Button>
          <Button
            {...(isMobile ? { sx: { flex: 1 } } : { width: 177 })}
            onClick={eventHandler(ETrackingEvent.BTN_UPDATE, () => save())}
            className={Style.customeSubmitBtn}
          >
            Update
          </Button>
        </STFloatAction>
      )}
    </Setting>
  )
}
