import { Box, ButtonBase, Typography, useTheme } from '@mui/material'
import { AxiosError } from 'axios'
import { ChangeEventHandler, FC, FormEventHandler, useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AuthApi } from 'src/api'
import { AlertMessage, Button, Input, NotAllowAuthenticated, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useAppDispatch, useOldValidation, useQueryParams, useUnsubscribe } from 'src/hooks'
import { IconGoogle, IconInfo } from 'src/icons'
import { Logo } from 'src/images'

import { ERoutes, generate } from 'src/router'
import { setLayoutSharingToken } from 'src/store/actions'
import { AUTH_LOGIN_SUCCESS } from 'src/store/types'
import { getApiErrorMessage } from 'src/utils'
import { signInSchema } from 'src/validation'
import { Header } from '../header'
import Style from './style.module.scss'

export const SignIn: FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const unsubscribe$ = useUnsubscribe()
  const dispatch = useAppDispatch()
  const { eventHandler } = useAnalytic('signin')
  const { errors, validate } = useOldValidation<{ email?: string; password?: string }>()
  const [error, setError] = useState<string>('')
  const { sharingToken } = useQueryParams()

  useEffect(() => {
    if (sharingToken) {
      dispatch(setLayoutSharingToken(sharingToken.toString()))
    }
  }, [sharingToken, dispatch])

  const [formValues, setFormValues] = useState<{ email?: string; password?: string}>({})

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.persist()
    const { name, value } = e.target
    setFormValues(
      (prev) => ({
        ...prev,
        [name]: value
      })
    )
  }

  const [loading, setLoading] = useState(false)
  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(async (e) => {
    e.preventDefault()
    const isValid = await validate({ schema: signInSchema, data: { ...formValues } })
    if (!isValid) {
      return
    }

    setError('')
    setLoading(true)

    from(AuthApi.login(formValues))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          setError(getApiErrorMessage(error))
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe((response) => {
        dispatch({
          type: AUTH_LOGIN_SUCCESS,
          payload: response.data.profile
        })
      })
  }, [formValues, dispatch, unsubscribe$, validate])

  useEffect(() => {
    if (errors.email) {
      eventHandler(ETrackingEvent.ERROR_EMAIL_DOMAIN)()
    }

    if (errors.password) {
      eventHandler(ETrackingEvent.ERROR_PSWD_BAD)()
    }
  }, [errors, eventHandler])

  return (
    <NotAllowAuthenticated>
      <div className={Style.Header}>
        <Header/>
      </div>
      <div className={Style.Container}>

        <div className={Style.MainSide}>
          <div className={Style.STContainer}>
            <form className={Style.STForm} onSubmit={onSubmit} autoComplete="off">
              <div className={Style.Logo}>
                <img src={Logo} alt="logo"/>
              </div>
              <div className={Style.LoginHeader}>
                <Typography className={Style.Title}>Sign In to KNOWME<span>TM</span></Typography>
                <Typography align="center" className={Style.Description}>
                  Hiring
                </Typography>
              </div>
              {error && (
                <AlertMessage
                  className={Style.ErrorMsg}
                  message={error}
                  mb={4}
                  icon={<IconInfo/>}
                />
              )}
              <Box className={Style.SigninWithGoogle}>
                <ButtonBase>
                  <IconGoogle/>&nbsp;&nbsp;
                  <Typography align="center" variant="body1-bold" color={theme.colors['--color-neutral-theme-700']}>
                    Continue with Google
                  </Typography>
                </ButtonBase>
                <Typography align="center" variant="body2-regular" color={theme.colors['--color-neutral-theme-250']} className={Style.WithEmail}>
                  or sign in with email
                </Typography>
              </Box>

              <div className={Style.FormInput}>
                <Input
                  label="Work Email"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="name@company.com"
                  disabled={loading}
                  value={formValues.email || ''}
                  onChange={handleChangeInput}
                  error={errors.email}
                  onClick={eventHandler(ETrackingEvent.INPUT_EMAIL)}
                  mb={0}
                  labelClassName={Style.Label}
                />
                <div className={Style.ForgotPassword}>
                  <Link
                    className={Style.Link}
                    to={generate(ERoutes.FORGOT_PASSWORD)}
                    onClick={eventHandler(ETrackingEvent.BTN_FORGOT_PSWD)}
                  >
                    Forgot your password?
                  </Link>
                </div>
                <Input
                  label="Password"
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  disabled={loading}
                  value={formValues.password || ''}
                  onChange={handleChangeInput}
                  error={errors.password}
                  onClick={eventHandler(ETrackingEvent.INPUT_SIGN_IN_PSWD)}
                  mb={0}
                  labelClassName={Style.Label}
                />

              </div>

              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box className={Style.Box}>
                  <Typography
                    variant="body2-medium"
                    color={theme.colors['--color-neutral-theme-400']}
                  >New to KNOWME?
                  </Typography>
                  <Typography
                    variant="body2-medium"
                    component="a"
                    color={theme.colors['--color-blue-500']}
                    onClick={eventHandler(ETrackingEvent.BTN_SIGNUP, () => history.push(generate(ERoutes.SIGN_UP)))}
                    sx={{ cursor: 'pointer' }}
                  >
                    Create account
                  </Typography>
                </Box>
                <Button
                  className="round-16"
                  kmSize="medium"
                  order="black"
                  type="submit"
                  disabled={loading}
                >
                  Sign in
                </Button>
              </Box>
            </form>
          </div>
        </div>
      </div>
    </NotAllowAuthenticated>
  )
}
