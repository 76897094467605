import { Box, Collapse, Divider, Fade, Typography, useTheme } from '@mui/material'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { debounce } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, merge, takeUntil, tap, timer } from 'rxjs'
import { CandidateApi, GuideApi } from 'src/api'
import { Button, Comment, IProgress, NoTabletOrMobile, OnlyMobile, ReactionAndShare, Share, TabletAndMobile, Tabs, Textarea, VideoPlayer, useAnalytic } from 'src/components'
import { useCache } from 'src/components/video-player/cache.context'
import { ECandidateReaction, EMessage, EReactionTable, ETrackingEvent, EUserGuide } from 'src/enums'
import { useAppDispatch, useAppSelector, useElementSize, useIfMobileL, useQueryParams, useSubscribeToGoBack, useUnsubscribe, useUnsubscribeCallback } from 'src/hooks'
import { IconEmail, IconLinkedInInfo, IconPhone } from 'src/icons'
import { ICandidateModel, ITab } from 'src/interfaces'
import { CandidateInfoEducationSection, CandidateInfoExperiencesSection, CandidateSkillsNChars, DialogVerifyProfile } from 'src/partials'
import { ERoutes, generate } from 'src/router'
import { OverlayService, SnackbarService } from 'src/services'
import { setLayoutLoading, setLayoutPageTitle, setLayoutShouldShowLoading } from 'src/store/actions'
import { setAuthStats } from 'src/store/actions/auth'
import { getAuthStat, getCredentials } from 'src/store/selectors'
import { AUTH_GET_PROFILE } from 'src/store/types'
import { formatDateTime, getApiErrorMessage, getVideoSource, videoFly } from 'src/utils'
import { NonIntroExperiencesSection } from './components/non-intro-experiences'
import Style from './style.module.scss'

const tabs: ITab[] = [
  { title: 'Profile' },
  { title: 'Activity' }
]

const reactionMobileBoxSx = { display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '16px' }

interface IProps {
  id: number
  onBack?: (callback?: () => void) => void
  onIntroductionSuccess?: (candidateId: number) => void
  onSync?: (candidate: ICandidateModel) => void
}

const PAGE_TITLE = 'Likes/Details'

export const LikesDetail: FC<IProps> = ({
  id,
  onBack,
  onIntroductionSuccess,
  onSync
}) => {
  const theme = useTheme()
  const isMobile = useIfMobileL()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const unsubscribe$ = useUnsubscribe()
  const containerRef = useRef<HTMLDivElement>(null)
  const [elWidth, elHeight] = useElementSize(containerRef.current)
  const { eventHandler, setScreen } = useAnalytic('')
  const { detailTab } = useQueryParams()
  const tab = useMemo(() => Number(detailTab) || 0, [detailTab])

  const cache = useCache()

  useEffect(() => {
    if (!tab) {
      setScreen('likes_profile')
    } else {
      setScreen('share_comments')
    }
  }, [tab, setScreen])

  const profile = useAppSelector(getCredentials)
  const userStat = useAppSelector(getAuthStat)

  const [candidate, setCandidate] = useState<ICandidateModel>()
  const [forceRender, setForceRender] = useState(0)
  const [isPrivateNotesSaved, setIsPrivateNotesSaved] = useState<boolean>(false)
  const [note, setNote] = useState<string>('')
  const [isDoLater, setIsDoLater] = useState<boolean>(false)
  const [introducedData, setIntroducedData] = useState<ICandidateModel>()

  const isIntroduced = useMemo(() => !!candidate?.interviewed, [candidate])
  const isSubscribed = useMemo(() => !!candidate?.hasSubscribedForLinkedin, [candidate])
  const linkedinWorkingExperiences = useMemo(() => candidate?.linkedinWorkingExperiences || [], [candidate])
  const linkedinEducations = useMemo(() => candidate?.linkedinEducations || [], [candidate])

  useEffect(() => {
    if (isIntroduced && candidate?.id) {
      from(CandidateApi.detail(candidate.id))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error: AxiosError) => {
            SnackbarService.push({
              severity: EMessage.ERROR,
              content: getApiErrorMessage(error)
            })
            return EMPTY
          })
        )
        .subscribe(({ data }) => setIntroducedData(data))
    }
  }, [isIntroduced, candidate?.id, unsubscribe$])

  const handleOnClickBack = useCallback(() => {
    onBack?.(() => {
      if (candidate && isIntroduced) {
        setTimeout(() => {
          videoFly({
            videoId: `video${candidate.id}`,
            sidebarId: 'intros',
            url: candidate.video?.urlVideoImageThumbnail
          })
          onIntroductionSuccess?.(candidate.id)
        }, 100)
      } else {
        if (candidate && isIntroduced) {
          onIntroductionSuccess?.(candidate.id)
        }
      }
    })
  }, [candidate, isIntroduced, onBack, onIntroductionSuccess])

  useSubscribeToGoBack({
    showArrow: true,
    title: PAGE_TITLE,
    handler: handleOnClickBack
  })

  const fetchCandidate = useUnsubscribeCallback((_unsubscribe$, id: number) => {
    dispatch(setLayoutLoading(true))
    dispatch(setLayoutShouldShowLoading(true))
    from(CandidateApi.detail(id, 'like'))
      .pipe(
        takeUntil(merge(unsubscribe$, _unsubscribe$)),
        catchError((error: AxiosError) => {
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: getApiErrorMessage(error)
          })
          return EMPTY
        }),
        finalize(() => {
          dispatch(setLayoutLoading(false))
          dispatch(setLayoutShouldShowLoading(false))
        })
      )
      .subscribe(({ data }) => {
        setCandidate(data)
        setNote(data.privateNote?.content || '')
      })
  }, [])

  const [isWatched, setIsWatched] = useState<boolean>(false)
  const handleWatchedVideo = useCallback((videoId: number, playing: boolean) => {
    if (isWatched || !playing) {
      return
    }

    // silent
    CandidateApi.play(videoId)
    setIsWatched(true)
  }, [isWatched])

  const handleIntroduce = async () => {
    try {
      if (!candidate || isIntroduced) {
        return
      }

      // 3, 8, 11...
      if ((userStat.countHMIntroduces + 1) % 5 === 3 && !isDoLater && !profile.pfv) {
        OverlayService.setOverlay({
          open: true,
          onClose: () => setIsDoLater(true),
          content: <DialogVerifyProfile profileId={profile.id} onDoLater={() => setIsDoLater(true)}/>
        })

        return
      }

      dispatch(setLayoutLoading(true))
      dispatch(setLayoutShouldShowLoading(true))
      const { data: newUserStats } = await CandidateApi.intro({ candidateIds: [candidate.id] })
      dispatch(setAuthStats(newUserStats))
      setCandidate((prev) => prev
        ? ({
          ...prev,
          interviewed: { ...prev.interviewed }
        })
        : undefined
      )
      setIsDoLater(false)

      if (!profile.guide?.firstIntro) {
        GuideApi.edit(EUserGuide.FIRST_INTRO)

        dispatch({ type: AUTH_GET_PROFILE })
      }

      SnackbarService.push({
        severity: EMessage.SUCCESS,
        content: <span>You Have Successfully Used <span className="color-positive-500">1 Credit</span></span>,
        autoHideDuration: 2000,
        actionText: ' ',
        position: {
          vertical: isMobile ? 'bottom' : 'top',
          horizontal: 'center'
        }
      })
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally {
      dispatch(setLayoutLoading(false))
      dispatch(setLayoutShouldShowLoading(false))
    }
  }

  const handleChangeReaction = useCallback(async (reaction: ECandidateReaction) => {
    try {
      const candidateId = candidate?.id
      const videoId = candidate?.video?.id

      if (!candidateId) return
      dispatch(setLayoutLoading(true))
      dispatch(setLayoutShouldShowLoading(true))

      const payload = {
        reactionableType: EReactionTable.VIDEO,
        reactionableId: videoId
      }

      await CandidateApi.action(candidateId, reaction, payload)

      if (reaction === ECandidateReaction.SKIP) {
        onBack?.(() => history.push(generate(ERoutes.ARCHIVE)))
      } else {
        fetchCandidate(candidateId)
      }
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally {
      dispatch(setLayoutLoading(false))
      dispatch(setLayoutShouldShowLoading(false))
    }
  }, [candidate?.id, candidate?.video?.id, dispatch, fetchCandidate, history, onBack])

  const handleOpenShare = useCallback(() => {
    if (!candidate) return

    OverlayService.setOverlay({
      open: true,
      content: <Share videoId={candidate.video?.id} candidatId={candidate.id} setForceRender={setForceRender}/>
    })
  }, [candidate])

  const handleSetTab = (tabId: number) => {
    eventHandler({
      key: tabId === 0 ? ETrackingEvent.BTN_DETAIL_PROFILE : ETrackingEvent.BTN_DETAIL_COMMENTS,
      contextData: { candidateId: id }
    })()

    history.replace(`/likes?detailCandidateId=${id}&detailTab=${tabId}`)
  }

  useEffect(() => {
    dispatch(setLayoutPageTitle(PAGE_TITLE))

    if (id) {
      fetchCandidate(id)
    }
  }, [id, dispatch, fetchCandidate])

  useEffect(() => {
    return () => {
      if (isIntroduced) {
        handleOnClickBack()
      }
    }
  }, [isIntroduced, handleOnClickBack])

  useEffect(() => {
    return () => {
      if (candidate) {
        onSync?.(candidate)
      }
    }
  }, [candidate, onSync])

  const isSuperNova = useMemo(
    () => candidate?.reaction?.reaction === ECandidateReaction.SUPER_NOVA,
    [candidate]
  )
  const isSuperLike = useMemo(
    () => candidate?.reaction?.reaction === ECandidateReaction.SUPER_LIKE,
    [candidate]
  )
  const isLike = useMemo(
    () => candidate?.reaction?.reaction === ECandidateReaction.LIKE,
    [candidate]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSavePrivateNotes = useCallback(debounce((unsubscribe1$, unsubscribe2$, candidateId, content) => {
    from(CandidateApi.note(candidateId, { content }))
      .pipe(
        takeUntil(merge(unsubscribe1$, unsubscribe2$)),
        tap(() => {
          setIsPrivateNotesSaved(true)
          eventHandler({
            key: ETrackingEvent.INPUT_PRIVATE_NOTES,
            contextData: { candidateId: id }
          })()
        }),
        catchError((error: AxiosError) => {
          SnackbarService.push({
            severity: EMessage.ERROR,
            content: getApiErrorMessage(error)
          })
          return EMPTY
        })
      )
      .subscribe(() => {
        timer(3000).pipe(takeUntil(merge(unsubscribe1$, unsubscribe2$))).subscribe(() => {
          setIsPrivateNotesSaved(false)
        })
      })
  }, 300), [])

  const handlePrivateNotes = useUnsubscribeCallback((_unsubscribe$, e) => {
    if (!candidate) return

    e.persist()
    setIsPrivateNotesSaved(false)
    setNote(e.target.value)
    handleSavePrivateNotes(_unsubscribe$, unsubscribe$, candidate.id, e.target.value)
  }, [candidate, handleSavePrivateNotes])

  const handleProgress = useCallback((progress: IProgress) => {
    if (candidate?.video.id) {
      cache.set(candidate.video.id, progress.playedSeconds)
    }
  }, [candidate, cache])

  return (
    <div
      ref={containerRef}
      className={Style.likesDetailScreen}
    >
      {candidate?.id && (
        <div
          className={Style.candidateDetail}
          style={{ [(elWidth / elHeight) > (1076 / 826) ? 'height' : 'width']: '100%' }}
        >
          <div className={clsx('p-4 fx fx-column gap-4', Style.videoContainer, { [Style.isSuperNova]: isSuperNova })}>
            <VideoPlayer
              className={Style.videoPlayer}
              id="video"
              videoId={candidate?.video?.id}
              tracks={candidate?.video?.tracks}
              trackingEvent
              playAt={cache.data.get(candidate?.video.id || -1)}
              onProgress={handleProgress}
              image={candidate?.video?.urlVideoImageThumbnail}
              animatedImage={candidate?.video?.urlVideoAnimatedImage}
              url={getVideoSource(candidate?.video)}
              style={{ borderRadius: '16px' }}
              onPlayingChange={(playing) => candidate?.video?.id && handleWatchedVideo(candidate.video.id, playing)}
              mimeType={candidate?.video?.internalSourceMetadata?.mimeType}
            />
            <Button
              className={Style.btnIntroduce}
              onClick={eventHandler(ETrackingEvent.BTN_INTRODUCE, handleIntroduce)}
              order={isIntroduced ? 'secondary' : 'primary'}
              disabled={isIntroduced}
            >
              {isIntroduced ? 'Introduced' : 'Introduce Me'}
            </Button>

            <TabletAndMobile>
              <ReactionAndShare
                sx={reactionMobileBoxSx}
                isLike={isLike}
                isSuperLike={isSuperLike}
                isSuperNova={isSuperNova}
                onShareClick={handleOpenShare}
                onReactionChange={handleChangeReaction}
                withText
              />
            </TabletAndMobile>
          </div>

          <OnlyMobile>
            <div className={Style.mobileDivider}/>
          </OnlyMobile>

          <div className={clsx('fx fx-column', Style.infoContainer, { [Style.infoContainer_showingComment]: tab === 1 })}>
            <div className={clsx('fx fx-ai-center fx-jc-space-between px-6', { [Style.isSuperNovaHeader]: isSuperNova }, Style.tabSpacing)}>
              <NoTabletOrMobile>
                <ReactionAndShare
                  isLike={isLike}
                  isSuperLike={isSuperLike}
                  isSuperNova={isSuperNova}
                  onShareClick={handleOpenShare}
                  onReactionChange={handleChangeReaction}
                />
              </NoTabletOrMobile>
              <Tabs tab={tab} tabs={tabs} setTab={handleSetTab}/>
            </div>

            <Divider/>

            {tab === 0 && (
              <div style={{ overflow: 'auto' }}>
                <Collapse in={!!introducedData} timeout={2000}>
                  <>
                    {introducedData && (
                      <>
                        <Box padding="16px 24px">
                          <h5 className={Style.FullNameTitle}>{introducedData?.fullName}</h5>
                          {/* <p className={Style.LabelMethod}>Meet {introducedData?.fullName} using these methods: </p> */}
                          <div className={Style.STPersonal}>
                            <Box>
                              <IconPhone/>
                              <Typography variant="body1-regular">{introducedData?.userPhoneNo ? <a className={Style.ALink} href={`tel:+${introducedData?.userPhoneNo}`}>{introducedData?.userPhoneNo}</a> : 'Working On It'}</Typography>
                            </Box>
                            {candidate?.email && (
                              <Box>
                                <IconEmail width={20} height={20}/>
                                <Typography variant="body1-regular">
                                  <a className={Style.ALink} href={`mailto:${introducedData.email}`}>{introducedData.email}</a>
                                </Typography>
                              </Box>
                            )}
                            {introducedData?.linkedInUrl && (
                              <Box>
                                <IconLinkedInInfo/>
                                <Typography variant="body1-regular">
                                  <a className={Style.ALink} target="_blank" href={introducedData.linkedInUrl} rel="noreferrer">{introducedData.linkedInUrl}</a>
                                </Typography>
                              </Box>
                            )}
                          </div>
                        </Box>

                        <Divider/>
                        <div className="fx fx-column px-6 py-4">
                          <p className={Style.LabelText}>
                            Available Date
                          </p>
                          <Typography mt="4px" variant="body2-bold" color={theme.colors['--color-positive-500']}>
                            {introducedData?.createdAt && formatDateTime(introducedData?.createdAt)}
                          </Typography>
                        </div>

                        <Divider/>
                      </>
                    )}
                  </>
                </Collapse>

                <div className="px-6 py-4 relative">
                  <p className={Style.LabelText}>Private Notes</p>
                  <Textarea
                    label=""
                    placeholder="Only you can see your private notes"
                    value={note}
                    onChange={handlePrivateNotes}
                    autoComplete="off"
                    disableResize
                    minRows={6}
                    maxRows={6}
                    style={{ marginTop: 8, fontSize: '14px' }}
                  />
                  <Fade in={isPrivateNotesSaved}>
                    <Typography mt="4px" variant="body2" color={theme.colors['--color-neutral-theme-300']}>Note saved</Typography>
                  </Fade>
                </div>

                {!introducedData && (
                  <>
                    <NonIntroExperiencesSection
                      linkedinWorkingExperiences={linkedinWorkingExperiences}
                      candidateId={id}
                      isSubscribed={isSubscribed}
                      onSubscribeChanged={() => setCandidate((prev) => prev
                        ? ({
                          ...prev,
                          hasSubscribedForLinkedin: !prev.hasSubscribedForLinkedin
                        })
                        : undefined
                      )}
                    />

                    {linkedinEducations.length > 0 && (
                      <CandidateInfoEducationSection linkedinEducations={linkedinEducations}/>
                    )}
                  </>
                )}

                {introducedData && linkedinWorkingExperiences.length > 0 && (
                  <CandidateInfoExperiencesSection linkedinWorkingExperiences={linkedinWorkingExperiences}/>
                )}

                {introducedData && linkedinEducations.length > 0 && (
                  <CandidateInfoEducationSection linkedinEducations={linkedinEducations}/>
                )}

                <CandidateSkillsNChars personalAttributes={introducedData?.personalAttributes}/>

                <div className={Style.Line}/>

                {introducedData && (
                  <div className={Style.STIntroduceDate}>
                    <Typography
                      variant="body2-regular"
                      color={theme.colors['--color-neutral-theme-300']}
                    >
                      You were introduced
                      to {introducedData?.fullName} on {introducedData?.interviewed?.createdAt && formatDateTime(introducedData?.interviewed?.createdAt)}
                    </Typography>
                  </div>
                )}
              </div>
            )}

            {tab === 1 && !!candidate?.id && !!candidate?.video?.id && (
              <Comment
                className="fx-1"
                showCommentInput
                forceRender={forceRender}
                candidateId={candidate.id}
                videoId={candidate.video.id}
                reaction={candidate.reaction}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
