import { Avatar, IconButton, Typography } from '@mui/material'
import { FC, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useAppSelector, useBehaviorMapper } from 'src/hooks'
import {
  IconArrowLeft,
  IconClose,
  IconLocation,
  IconMoney
} from 'src/icons'
import { CampaignMutationService, CampaignService } from 'src/services'
import { getCredentials } from 'src/store/selectors'
import { getFirstName, stringGrayAvatar } from 'src/utils'
import { CompanySection } from '../company-section'
import { VideoSection } from '../video-section'
import { PreviewFooter } from './components/footer'
import { JobSummary } from './components/job-summary'
import { useJobLocation, useJobMediaUrl, useSalaryValue } from './hooks'
import Style from './style.module.scss'

const avatarSmallSize = {
  height: 80,
  width: 80,
  borderRadius: '12px'
}

export const PreviewCampaign: FC = () => {
  const history = useHistory()
  const data = useBehaviorMapper(CampaignMutationService.data$)
  const profile = useAppSelector(getCredentials)

  useEffect(() => {
    if (data && !CampaignService.isDataValid(data)) {
      history.goBack()
    }
  }, [data, history])

  const { uploadVideoUrl, logoUrl } = useJobMediaUrl()
  const jobLocation = useJobLocation()
  const salaryValue = useSalaryValue()
  // const requirements = useRequirements()

  return (
    <div className={Style.container}>
      <div className={Style.header}>
        <IconButton className={Style.buttonBack} onClick={() => history.goBack()}>
          <IconArrowLeft/>
        </IconButton>
        <div>
          <span className="subtitle-bold color-neutral-theme-250">Edit / <span className="subtitle-bold color-neutral-theme-700">Job Preview</span></span>
        </div>
      </div>
      <div className={Style.body}>
        <div className={Style.content}>
          <IconButton
            className={Style.close}
            onClick={() => history.goBack()}
          >
            <IconClose/>
          </IconButton>

          <div className={Style.jobSection}>
            <VideoSection
              recruiterName={getFirstName(profile)}
              uploadVideoUrl={uploadVideoUrl}
              thumbnail={data.uploadAudioBackground?.file || data.uploadAudioBackground?.src || data.uploadVideo?.urlVideoImageThumbnail}
            />

            <div className={Style.jobInfo}>
              {logoUrl
                ? (
                  <div className={Style.Avatar}>
                    <img
                      alt="company-logo"
                      src={logoUrl}
                    />
                  </div>
                )
                : (
                  <Avatar
                    alt="company-logo"
                    {...stringGrayAvatar(data.companyName, avatarSmallSize)}
                  />
                )}

              <div className="fx flex-column gap-1">
                <Typography variant="h4">{data.jobTitle}</Typography>
                <span className="subtitle-regular color-neutral-theme-400">
                  {data.companyName}
                </span>
              </div>

              <div className="fx flex-column gap-10px">
                <div className="fx flex-row gap-10px fx-ai-start">
                  <IconLocation className="mt-1 svg-color-primary-700 fx-shrink-0"/>
                  <span className="body1-medium color-neutral-theme-900">{jobLocation[0]} <span className="body1-regular color-neutral-theme-400">{jobLocation[1]}</span></span>
                </div>

                <div className="fx flex-row gap-10px fx-ai-start fx-shrink-0">
                  <IconMoney className="mt-1 svg-color-primary-700"/>
                  <span className="body1-medium color-neutral-theme-900">{salaryValue}</span>
                </div>
              </div>

              <JobSummary
                maxLineSummary={7}
                summary={data.jobSummary}
                description={data.briefDescription}
                url={data?.jdFileUrl}
                file={data?.jdFile}
                fileName={data?.uploadedJdFile?.filename}
              />

              {/* <div className="fx flex-column gap-18px">
                <div className="fx flex-column gap-2">
                  <span className="body1-bold color-neutral-theme-900">Application Requirements</span>
                  <span className="body2-regular color-neutral-theme-400 relative">
                    {requirements}
                  </span>
                </div>
              </div> */}
            </div>
          </div>
          <CompanySection
            logoUrl={logoUrl}
            companyName={data.companyName}
            companyWebsiteUrls={data.companyWebsiteUrls}
          />
        </div>
      </div>
      <PreviewFooter className={Style.footer}/>
    </div>
  )
}
