import clsx from 'clsx'
import { cloneDeep, debounce, uniqWith } from 'lodash'
import { ChangeEvent, FC, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useHistory, useParams } from 'react-router'
import { CampaignApi } from 'src/api'
import {
  Button,
  EmptyVideos,
  FilterDropdown,
  FlipCard,
  FlipCardBack,
  IconWrapper,
  InputSearch,
  Share,
  Tooltip,
  VideoPlayer,
  useAnalytic
} from 'src/components'
import { EMessage, ESubmissionActions, ESubmissionReaction, ETrackingEvent } from 'src/enums'
import { useAppDispatch, useAppSelector, useQueryParams, useSubscribeToGoBack } from 'src/hooks'
import { IconCopyWhite, IconGroup, IconTrash } from 'src/icons'
import { IntroCardEmptyImg, LikeCardEmptyImg } from 'src/images'
import { ICampaignModel, ICampaignSubmissionModel, IPaginationResponse } from 'src/interfaces'
import { DeleteConfirmPopup } from 'src/modules/campaign/components/delete-confirm-popup'
import { STFlipFront } from 'src/modules/intros/components/list/styled'
import { ERoutes, generate } from 'src/router'
import { CampaignMutationService, NavigationService, OverlayService, SnackbarService } from 'src/services'
import { FirstTimeClaimedJobTourService } from 'src/services/tour/first-time-claimed-job.service'
import { ShareJobTourService, TARGET_JOB_SHARE_BUTTON } from 'src/services/tour/share-job-tour.service'
import {
  setLayoutAside,
  setLayoutLoading,
  setLayoutScrollToBottom,
  setLayoutShouldShowLoading,
  setLayoutShouldShowShareButtonHeaderMobile
} from 'src/store/actions'
import { getGuide, getLayoutIsLoading, getLayoutIsScrollToBottom } from 'src/store/selectors'
import { ensureArray, getApiErrorMessage, getVideoSource } from 'src/utils'
import { ECampaignRoutes } from '../../routes.enum'
import { getCampaignShareLink } from '../../utils'
import { Empty } from '../empty'
import { JobDetailPopup } from '../job-detail-popup'
import { CampaignOwners } from '../my-jobs/components/campaign-owners'
import { SubmissionDetail } from '../submission-detail'
import { ButtonEdit } from './components/button-edit'
import { defaultFilterOptions, defaultViewOptions, icons } from './constants'
import Style from './style.module.scss'

const mapSubmissionReaction = (submission: ICampaignSubmissionModel): ICampaignSubmissionModel => {
  return {
    ...submission,
    video: {
      ...submission.video,
      actionLike: submission.submissionReaction?.reaction === ESubmissionReaction.LIKE
    }
  }
}

export const Campaigns: FC = () => {
  const { eventHandler } = useAnalytic('campaigns')
  const history = useHistory()
  const dispatch = useAppDispatch()

  const isScrollToBottom = useAppSelector(getLayoutIsScrollToBottom)
  const isLoading = useAppSelector(getLayoutIsLoading)
  const guide = useAppSelector(getGuide)
  const { detailSubmissionId: paramDetailSubmissionId } = useQueryParams()
  const [detailSubmissionId, setDetailSubmissionId] = useState<number>()

  const { id } = useParams<{ id: string }>()
  const campaignId = +id

  const [data, setData] = useState<IPaginationResponse<ICampaignSubmissionModel>>({
    page: 1,
    pages: 1,
    total: 0,
    rows: []
  })

  const [filterOptions, setFilterOptions] = useState(defaultFilterOptions)
  const [filterDisplayOptions, setFilterDisplayOptions] = useState(defaultFilterOptions)
  const [viewOptions, setViewOptions] = useState(cloneDeep([...defaultViewOptions]))
  const [searchTerm, setSearchTerm] = useState('')
  const [currOpenedTraitId, setCurrOpenedTraitId] = useState(0)
  const [link, setLink] = useState('')
  const [isReactionSending, setIsReactionSending] = useState(false)
  const [currentCampaign, setCurrentCampaign] = useState<ICampaignModel>()
  const [isCopySuccess, setIsCopySuccess] = useState(false)
  const [isChangeView, setIsChangeView] = useState(false)
  const [isShowSearchBar, setIsShowSearchBar] = useState(false)

  const watchedTimeMapRef = useRef<Map<number, number>>(new Map())
  const likeItemRef = useRef<HTMLDivElement>(null)

  const [lastLikeOffsetY, setLastLikeOffsetY] = useState<number>()
  const containerRef = useRef<any>()

  useEffect(() => {
    if (!detailSubmissionId && containerRef.current) {
      containerRef.current.scrollTo(0, lastLikeOffsetY || 0)
    }
  }, [detailSubmissionId, lastLikeOffsetY])

  const handleBeforeGoToDetail = useCallback(() => {
    setLastLikeOffsetY(containerRef.current?.scrollTop)
  }, [containerRef])

  const currentView = useMemo(() => viewOptions.find(option => option.isChecked), [viewOptions])

  const loadSubmission = useCallback(
    async (
      campaignId: number,
      page = 1,
      limit = 10,
      isReaction = false
    ) => {
      try {
        if (campaignId === currentCampaign?.id) {
          if (isReaction) {
            dispatch(setLayoutLoading(false))
          } else {
            dispatch(setLayoutLoading(true))
          }

          const conditions: any = {
            page,
            limit
          }

          if (filterOptions.length) {
            const currentOption = filterOptions.find(option => option.isChecked)
            if (currentOption && currentOption.value !== currentCampaign?.traitFilter?.orderOption) {
              conditions.order = currentOption.value
            }
          }

          if (searchTerm) {
            conditions.search = searchTerm
          }

          const { data } = await CampaignApi.paginateSubmission(
            campaignId,
            conditions
          )

          setData((prev) => ({
            ...prev,
            page: data.page,
            total: data.total,
            pages: data.pages,
            rows: (!conditions.page || conditions.page === 1)
              ? [...ensureArray(data.rows)].map(mapSubmissionReaction)
              : uniqWith([
                ...ensureArray(prev.rows),
                ...ensureArray(data.rows).map(mapSubmissionReaction)
              ], (a, b) => a.id === b.id)
          }))
        }
      } catch (error) {
        SnackbarService.push({
          severity: EMessage.ERROR,
          content: getApiErrorMessage(error)
        })
      } finally {
        dispatch(setLayoutLoading(false))
      }
    }, [currentCampaign?.id, currentCampaign?.traitFilter?.orderOption, filterOptions, searchTerm, dispatch]
  )

  const handleCampaignDetail = useCallback((id: number) => {
    dispatch(setLayoutLoading(true))

    CampaignApi.getOne(id)
      .then(({ data }) => {
        setCurrentCampaign(data)
        setLink(getCampaignShareLink(data.slug))
      })
  }, [dispatch])

  const handleReaction = useCallback(async (action: ESubmissionReaction, submission: ICampaignSubmissionModel) => {
    try {
      if (!campaignId) return
      const dataRows = data.rows?.map((item: ICampaignSubmissionModel) => {
        if (item.video.id === submission.video.id) {
          return { ...item, video: { ...item.video, actionLike: !item.video.actionLike } }
        }
        return item
      })

      setData((prev) => ({
        ...prev,
        rows: dataRows
      }))

      setIsReactionSending(true)

      await CampaignApi.action({
        campaignId,
        submissionId: submission.id,
        action,
        videoId: submission.video.id
      })
    } catch (error) {
      SnackbarService.push({
        severity: EMessage.ERROR,
        content: getApiErrorMessage(error)
      })
    } finally {
      setIsReactionSending(false)
    }
  }, [data.rows, campaignId])

  const handleOnCopy = useCallback(() => {
    eventHandler(ETrackingEvent.CAMPAIGN_BTN_JOBS_COPY_URL)()
    setIsCopySuccess(true)

    ShareJobTourService.nextStep()
  }, [eventHandler])

  const handleEditCampaign = useCallback(() => {
    CampaignMutationService.reset()
    // eventHandler(ETrackingEvent.CAMPAIGN_BTN_JOB_EDIT)

    eventHandler({
      key: ETrackingEvent.CAMPAIGN_BTN_JOB_EDIT,
      contextData: {
        campaignId
      }
    })()

    history.push(`/campaigns/${campaignId}`)
  }, [history, campaignId, eventHandler])

  const handleChooseFilterOption = useCallback(async (option: {
    label: string
    value: string
    isChecked: boolean
    isDefault?: boolean
  }) => {
    const updatedFilterOptions = await Promise.all(defaultFilterOptions.map(async (obj) => {
      if (currentCampaign?.id && obj.value === option.value) {
        if (!option.isDefault) {
          eventHandler({
            key: ETrackingEvent.CAMPAIGN_SELECT_JOBS_SORT,
            contextData: {
              campaignId: currentCampaign?.id,
              data: option.label
            }
          })()
        }

        return { ...obj, isChecked: true }
      }
      return obj
    }))
    if (!option.isDefault) {
      setFilterOptions(updatedFilterOptions)
    }
    setFilterDisplayOptions(updatedFilterOptions)
  }, [currentCampaign?.id, eventHandler])

  const handleChooseViewOption = useCallback(async (option: {
    label: string
    value: string
    isChecked: boolean
    isDefault?: boolean
  }) => {
    dispatch(setLayoutLoading(true))
    const updatedViewOptions = await Promise.all(defaultViewOptions.map(async (obj) => {
      if (currentCampaign?.id && obj.value === option.value) {
        if (!option.isDefault) {
          setIsChangeView(true)
          eventHandler({
            key: ETrackingEvent.CAMPAIGN_SELECT_JOBS_VIEW,
            contextData: {
              campaignId: currentCampaign?.id,
              data: option.label
            }
          })()
        }
        return { ...obj, isChecked: true }
      }
      return obj
    }))
      .finally(() => {
        dispatch(setLayoutLoading(false))
      })

    setViewOptions(updatedViewOptions)
  }, [currentCampaign?.id, eventHandler, dispatch])

  const handleSearch = useMemo(() => debounce(setSearchTerm, 500), [])

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    handleSearch(value)
  }

  const handelProgressVideo = (videoId: number, playedSecond: number) => {
    watchedTimeMapRef.current.set(videoId, playedSecond)
  }

  // const handleToggleTraitCollapse = useCallback((traitId: number) => {
  //   if (traitId === currOpenedTraitId) {
  //     setCurrOpenedTraitId(0)
  //     return
  //   }

  //   setCurrOpenedTraitId(traitId)
  // }, [currOpenedTraitId])

  const handleShare = (videoId: number, userId: number, submissionId: number) => {
    eventHandler({
      key: ETrackingEvent.CAMPAIGN_BTN_VIDEO_SHARE,
      contextData: {
        candidateId: userId,
        campaignId
      }
    })()

    OverlayService.setOverlay({
      open: true,
      content: <Share videoId={videoId} candidatId={userId} submissionId={submissionId}/>
    })
  }

  const handleClickFlip = useCallback((flipFunc: () => void) => {
    setIsChangeView(false)
    flipFunc()
  }, [])

  const handleClickIcons = useCallback((action: ESubmissionActions, submission: ICampaignSubmissionModel, flipFunc: () => void) => {
    switch (action) {
      case ESubmissionActions.INFO:
        return handleFlipButtonClick(flipFunc, submission.author.id, submission.video?.id)
      case ESubmissionActions.SHARE:
        return handleShare(submission.video.id, submission.author.id, submission.id)
      case ESubmissionActions.LIKE:
        return handleReaction(ESubmissionReaction.LIKE, submission)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleReaction])

  const handleFlipButtonClick = (flipFunc: () => void, candidateId: number, videoId?: number) => {
    eventHandler({
      key: ETrackingEvent.CAMPAIGN_BTN_VIDEO_INFO,
      contextData: {
        campaignId,
        candidateId
      }
    })()
    handleClickFlip(flipFunc)
  }

  const handleClickName = useCallback((item: ICampaignSubmissionModel) => {
    setLastLikeOffsetY(containerRef.current?.scrollTop)
    history.push({ search: `?detailSubmissionId=${item.id}&detailTab=${0}` })
  }, [history])

  const renderVideoComponent = useCallback((item: ICampaignSubmissionModel, flipFunc: () => void) => {
    return (
      <STFlipFront key={'front_' + item.id}>
        <div className={Style.STVideoWrapper}>
          <div
            className={clsx(
              Style.STVideoContainer,
              Style.videoContainerPaddingBottom
            )}
          >
            <div className={Style.STVideo}>
              <VideoPlayer
                id={'video' + item.id}
                trackingEvent
                onProgress={({ playedSeconds }) => handelProgressVideo(item.video.id, playedSeconds)}
                playAt={watchedTimeMapRef.current.get(item.video.id)}
                videoId={item?.video?.id}
                tracks={item?.video?.tracks}
                image={item?.video?.urlVideoImageThumbnail}
                animatedImage={item?.video?.urlVideoAnimatedImage}
                url={getVideoSource(item?.video)}
                style={{ borderRadius: '16px 16px 0 0' }}
                isStop={currOpenedTraitId === item.video.id}
                isMuted={false}
                mini
                author={item?.author}
                detailUrl={`?detailSubmissionId=${item.id}&detailTab=${0}`}
                onBeforeGoToDetail={handleBeforeGoToDetail}
                hideStartEnd
                mimeType={item?.video?.internalSourceMetadata?.mimeType}
                onPlayingChange={(playing) => {
                  if (playing && currOpenedTraitId === item.video.id) {
                    setCurrOpenedTraitId(0)
                  }
                }}
                action={(
                  <div className={clsx('fx', Style.Icons)}>
                    {icons.map(({ title, action, icon: Icon, color, active }) => (
                      <IconWrapper
                        key={title}
                        id={title}
                        width={32}
                        height={32}
                        color={color}
                        active={active}
                        disabled={action === ESubmissionActions.LIKE && isReactionSending}
                        className={clsx(Style.Icon, action === ESubmissionActions.LIKE && item.video?.actionLike && Style.IconLike)}
                        onClick={(e: ChangeEvent) => {
                          e.stopPropagation()
                          handleClickIcons(action, item, flipFunc)
                        }}
                      >
                        <Tooltip title={title} placement="left">
                          <Icon width={21} height={21} className="svg-color-neutral-theme-50"/>
                        </Tooltip>
                      </IconWrapper>
                    ))}
                  </div>
                )}
                optionalTrackEventData={{ campaignId: campaignId }}
                showSpeed
              />
            </div>
          </div>
        </div>
      </STFlipFront>
    )
  }, [currOpenedTraitId, handleBeforeGoToDetail, campaignId, isReactionSending, handleClickIcons])

  const renderSexeComponent = useCallback((item: ICampaignSubmissionModel, flipFunc: () => void) => {
    return (
      <FlipCardBack
        key={'back_' + item.id}
        onFlip={() => { handleClickFlip(flipFunc) }}
        educations={item.author?.linkedinEducations}
        workingExperiences={item.author?.linkedinWorkingExperiences}
        video={item.video}
        author={item.author}
        showFullName
        onDetailClick={() => handleClickName(item)}
      />
    )
  }, [handleClickFlip, handleClickName])

  // everytime currentCampaign changed, re-fetch related submissions
  useEffect(() => {
    if (currentCampaign?.id) {
      loadSubmission(currentCampaign?.id)
    }
  }, [loadSubmission, currentCampaign?.id])

  useEffect(() => {
    if (campaignId) {
      handleCampaignDetail(campaignId)
    }
  }, [dispatch, handleCampaignDetail, campaignId])

  // set page title when page entered
  useEffect(() => {
    dispatch(setLayoutShouldShowShareButtonHeaderMobile(false))
    dispatch(setLayoutShouldShowLoading(false))
    dispatch(setLayoutAside(true))

    return () => {
      dispatch(setLayoutShouldShowShareButtonHeaderMobile(true))
      dispatch(setLayoutShouldShowLoading(true))
    }
  }, [dispatch])

  useEffect(() => {
    if (guide?.campaignId && (guide.campaignId === currentCampaign?.id)) {
      FirstTimeClaimedJobTourService.startTour()
    } else {
      FirstTimeClaimedJobTourService.stopTour()
    }
  }, [currentCampaign, guide])

  const pageTitle = useMemo(() => {
    if (detailSubmissionId) {
      return `My Jobs/${currentCampaign?.jobTitle || ''}/Detail`
    }

    return `My Jobs/${currentCampaign?.jobTitle || ''}`
  }, [currentCampaign?.jobTitle, detailSubmissionId])

  useSubscribeToGoBack({
    showArrow: true,
    title: pageTitle,
    titleActions: detailSubmissionId
      ? [
        { title: 'My Jobs', handler: () => history.push(generate(ERoutes.CAMPAIGN_MY_JOBS)) },
        { title: currentCampaign?.jobTitle || '', handler: () => history.push(generate([ERoutes.CAMPAIGNS, { id: currentCampaign?.id }])) },
        { title: 'Detail', handler: () => null }
      ]
      : [
        { title: 'My Jobs', handler: () => history.push(generate(ERoutes.CAMPAIGN_MY_JOBS)) },
        { title: currentCampaign?.jobTitle || '', handler: () => null }
      ]
  })

  useEffect(() => {
    NavigationService.setCreditPoint(false)

    return () => {
      NavigationService.setCreditPoint(true)
    }
  }, [])

  // when scroll to bottom, load more submissions
  useEffect(() => {
    if (
      currentCampaign?.id &&
      data.pages &&
      data.page &&
      data.page !== data.pages &&
      isScrollToBottom &&
      !detailSubmissionId
    ) {
      loadSubmission(currentCampaign.id, data.page + 1)
    }
  }, [data.page, data.pages, dispatch, isScrollToBottom, loadSubmission, currentCampaign, detailSubmissionId])

  useEffect(() => {
    setDetailSubmissionId(
      paramDetailSubmissionId
        ? +paramDetailSubmissionId
        : undefined
    )
  }, [paramDetailSubmissionId])

  // first time loaded
  useEffect(() => {
    const currentFilterOption = defaultFilterOptions.find(item => item.value === currentCampaign?.traitFilter?.orderOption)
    handleChooseFilterOption(
      currentFilterOption
        ? { ...currentFilterOption, isChecked: true, isDefault: true }
        : {
          label: 'Newest First',
          value: 'NEWEST_FIRST',
          isChecked: true,
          isDefault: true
        }
    )
  }, [currentCampaign, handleChooseFilterOption])

  useEffect(() => {
    const currentViewOption = defaultViewOptions.find(
      (item) => item.value === currentCampaign?.traitFilter?.viewOption
    )
    handleChooseViewOption(
      currentViewOption
        ? { ...currentViewOption, isChecked: true, isDefault: true }
        : {
          label: 'View by VVC',
          value: 'VVC',
          isChecked: true,
          isDefault: true
        }
    )
  }, [currentCampaign, handleChooseViewOption])

  useEffect(() => {
    return () => {
      // Cleanup the debounce function on component unmount
      handleSearch.cancel()
    }
  }, [handleSearch])

  useEffect(() => {
    if (isCopySuccess) {
      const timer = setTimeout(() => {
        setIsCopySuccess(false)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [isCopySuccess, setIsCopySuccess])

  const handleListCampaignScroll = useCallback((e: SyntheticEvent<HTMLDivElement>) => {
    const element = (e.target as HTMLElement)
    const scrollBottom = Math.floor(element.scrollHeight - element.scrollTop) <= element.clientHeight

    if (scrollBottom) {
      return dispatch(setLayoutScrollToBottom(true))
    }

    if (isScrollToBottom) {
      return dispatch(setLayoutScrollToBottom(false))
    }
  }, [dispatch, isScrollToBottom])

  const handleShowSearchBarChanged = useCallback((isShowing: boolean) => {
    setIsShowSearchBar(isShowing)
  }, [])

  const handleOnDelete = useCallback(async () => {
    if (!campaignId) {
      return
    }

    await CampaignApi.archive(campaignId)
    history.push(generate(ECampaignRoutes.CAMPAIGN_MY_JOBS))
    OverlayService.reset()
  }, [campaignId, history])

  const openDeleteConfirmPopup = useCallback(() => {
    OverlayService.setOverlay({
      content: (
        <DeleteConfirmPopup
          onCancel={() => OverlayService.reset()}
          onDelete={handleOnDelete}
        />
      ),
      open: true
    })
  }, [handleOnDelete])

  const openJobDetail = () => {
    ShareJobTourService.nextStep()
    // window.open(`${EEnv.REACT_APP_LANDING_WEB_DNS}/campaigns/${currentCampaign?.slug}`, '_blank')
    OverlayService.setOverlay({
      content: <JobDetailPopup campaignId={campaignId}/>,
      open: true
    })
  }

  return (
    <div className={Style.STContainer}>
      {currentCampaign
        ? !detailSubmissionId
          ? (
            <div className={Style.listCampaignContent} onScroll={handleListCampaignScroll} ref={containerRef}>
              <div className={Style.STCampaignHeader}>
                <div className={Style.STCampaignHeaderLeft}>
                  {currentCampaign.companyDomain && (
                    <CampaignOwners
                      id={currentCampaign.id}
                      companyDomain={currentCampaign.companyDomain}
                      size="large"
                    />
                  )}
                  <Tooltip
                    title={currentCampaign.jobTitle}
                    arrow
                    placement="top"
                  >
                    <span className={Style.STCampaignHeaderTitle} onClick={openJobDetail}>
                      {currentCampaign.jobTitle}
                    </span>
                  </Tooltip>

                  <ButtonEdit onClick={handleEditCampaign}/>

                  <Button
                    type="button"
                    order="tertiary"
                    className={Style.STCampaignHeaderAction}
                    onClick={openDeleteConfirmPopup}
                  >
                    <IconTrash className="svg-color-neutral-theme-900"/>
                  </Button>
                  <CopyToClipboard text={link} onCopy={handleOnCopy}>
                    <Button
                      id={TARGET_JOB_SHARE_BUTTON.getValue()}
                      type="button"
                      order="tertiary"
                      className={Style.STCampaignHeaderAction}
                    >
                      <IconGroup/>
                    </Button>
                  </CopyToClipboard>

                  {isCopySuccess && (
                    <div className={Style.ToastCopy}>
                      <Button
                        type="button"
                        className={Style.BtnCopy}
                      >
                        <IconCopyWhite/>
                        Link Copied
                      </Button>
                    </div>
                  )}
                </div>

                <div className={Style.STCampaignHeaderRight}>
                  <InputSearch handleChange={handleChangeSearch} onShowSearchBarChanged={handleShowSearchBarChanged}/>
                  <FilterDropdown options={filterDisplayOptions} handleClickItem={handleChooseFilterOption} mobileLabel="Sort" wrapperClassName={clsx(isShowSearchBar && Style.FilterOption_isShowSearchBar)} className={Style.FilterOption}/>
                  <FilterDropdown
                    options={viewOptions}
                    handleClickItem={handleChooseViewOption}
                    mobileLabel="View"
                    wrapperClassName={clsx(isShowSearchBar && Style.ViewOption_isShowSearchBar)}
                    className={Style.ViewOption}
                  />
                </div>
              </div>
              {isLoading
                ? <EmptyVideos className={Style.emptyVideos} quantity={10} variant="applicant"/>
                : !data.rows?.length
                  ? (
                    <div className={Style.EmptyWrapper}>
                      <Empty
                        cover={LikeCardEmptyImg}
                        placeholder="Here Come the Talent…"
                        className={Style.EmptyContainer}
                      />
                    </div>
                  )
                  : (
                    <div className={Style.campaignContainerWrapper}>
                      <div className={Style.campaignContainerData}>
                        {data.rows?.map((item: ICampaignSubmissionModel) => {
                          return (
                            <div className={Style.STLikesItem} key={item.id} {...(item?.video?.videoTranscription ? { ref: likeItemRef } : {})}>
                              {currentView?.value === 'SEXE'
                                ? (
                                  <FlipCard isChange={isChangeView} key={1}>
                                    {(flipFunc) => [
                                      renderSexeComponent(item, flipFunc),
                                      renderVideoComponent(item, flipFunc)
                                    ]}
                                  </FlipCard>
                                )
                                : (
                                  <FlipCard isChange={isChangeView} key={2}>
                                    {(flipFunc) => [
                                      renderVideoComponent(item, flipFunc),
                                      renderSexeComponent(item, flipFunc)
                                    ]}
                                  </FlipCard>
                                )}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}

            </div>
          )
          : (
            <SubmissionDetail
              campaignId={campaignId}
              submissionId={detailSubmissionId}
            />
          )
        : (
          <div className={Style.STCampaignEmpty}>
            {/**
            * TODO: Add placeholder and change the cover when having content
            */}
            <Empty cover={IntroCardEmptyImg} placeholder=""/>
          </div>
        )}
    </div>
  )
}
