import { Box, Typography, useTheme } from '@mui/material'
import { ChangeEvent, FC, FormEvent } from 'react'
import { useHistory } from 'react-router'
import { AlertMessage, Button, Input, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { IconInfo } from 'src/icons'
import { Logo } from 'src/images'
import { ERoutes, generate } from 'src/router'
import { Header } from '../header'
import Style from './password-reset.module.scss'

interface PasswordResetProps {
  email: string
  error: string
  handleSubmit: (e: FormEvent<HTMLFormElement> & MouseEvent) => void
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void
}

export const PasswordReset: FC<PasswordResetProps> = ({
  handleChangeInput,
  handleSubmit,
  error,
  email
}) => {
  const theme = useTheme()
  const router = useHistory()
  const { eventHandler } = useAnalytic('pswd_reset1')

  const goToSignup = () => {
    eventHandler(ETrackingEvent.BTN_NOTIFICATIONS)()
    router.push(generate(ERoutes.SIGN_UP))
  }

  return (
    <>
      <div className={Style.Header}>
        <Header/>
      </div>
      <div className={Style.Container}>

        <div className={Style.MainSide}>
          <form className={Style.STForm} onSubmit={handleSubmit} autoComplete="off">
            <div className={Style.Logo}>
              <img src={Logo} alt="logo"/>
            </div>
            <div className={Style.ResetPasswordHeader}>
              <Typography variant="h5" color={theme.colors['--color-neutral-theme-700']} className={Style.Title}>Reset your password</Typography>
              <Typography variant="body2-regular" color={theme.colors['--color-neutral-theme-400']} className={Style.SubTitle}>
                Enter the email address associated with your account and we'll send you a link to reset your password.
              </Typography>
            </div>

            {error && (
              <AlertMessage
                className={Style.ErrorMsg}
                message={error}
                mb={4}
                icon={<IconInfo/>}
              />
            )}

            <div className={Style.FormInput}>
              <Input
                label="Email"
                id="email"
                type="email"
                name="email"
                placeholder="name@company.com"
                value={email || ''}
                onChange={handleChangeInput}
                onClick={eventHandler(ETrackingEvent.INPUT_EMAIL)}
                required
                mb={0}
                labelClassName={Style.Label}
              />

              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box className={Style.Box}>
                  <Typography
                    variant="body2-medium"
                    color={theme.colors['--color-neutral-theme-400']}
                  >New to KNOWME?
                  </Typography>
                  {' '}
                  <Typography
                    variant="body2-medium"
                    component="a"
                    color={theme.colors['--color-blue-500']}
                    onClick={goToSignup}
                    sx={{ cursor: 'pointer' }}
                  >
                    Create account
                  </Typography>
                </Box>
                <Button
                  className="round-16"
                  kmSize="medium"
                  order="black"
                  type="submit"
                  onClick={eventHandler(ETrackingEvent.BTN_SEND_RESET_PSWD_LINK)}
                >
                  Send
                </Button>
              </Box>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
