import { EEnv } from 'src/constants'
import { ELocationType } from 'src/enums'
import { ICampaignDetail } from 'src/interfaces'

const MAP_LOCATION = {
  [ELocationType.HYBRID]: 'Hybrid',
  [ELocationType.ON_SITE]: 'Onsite',
  [ELocationType.REMOTE]: 'Remote'
}

export const formatJobLocation = (data?: { location?: string; locationType?: ELocationType }) => {
  if (!data?.location) {
    if (data?.locationType && MAP_LOCATION[data.locationType]) {
      return [MAP_LOCATION[data.locationType], '']
    }

    return ['Not Provided', '']
  }

  if (data?.locationType && MAP_LOCATION[data.locationType]) {
    return [data.location, `(${MAP_LOCATION[data.locationType]})`]
  }

  return [data.location, '']
}

export const formatRequirements = (data?: Pick<ICampaignDetail, 'requireLinkedInUrl' | 'requireZipCode' | 'requiredResume'>) => {
  return [
    data?.requiredResume ? 'Resume' : '',
    data?.requireLinkedInUrl ? 'LinkedIn Profile' : '',
    data?.requireZipCode ? 'Zip Code of Resident' : ''
  ].filter(Boolean).join(', ')
}

export const getCampaignShareLink = (slug?: string) => `${EEnv.REACT_APP_LANDING_WEB_DNS}/campaigns/${slug}`
